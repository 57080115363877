import React from "react";
import "../styles/LandingPage.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import moonImage from "../images/moon.png";
import satelliteImage from "../images/satellite.png";
import SatelliteInterference from "./SatelliteInterference";
import StarsBackground from "../components/starsBackground";

const LandingPage = () => {
  const scrollToSatelliteInterference = () => {
    const satelliteInterferencePage = document.getElementById("satellite-interference-page");
    satelliteInterferencePage.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Container fluid className="landing-page">
        <StarsBackground numberOfStars={450} />
        <Row>
          <Col className="mx-auto text-center">
            <img src={moonImage} alt="Moon" className="moon-image" />

            <h1 className="landing-title">Satellite Interference Detection</h1>
            <p className="landing-subtitle">
              Find out if satellites may interfere with your next shot of the
              night sky.
            </p>
            <Button className="explore-button" onClick={scrollToSatelliteInterference}>Explore</Button>

            <div className="satellite-container">
              <img src={satelliteImage} alt="Satellite" className="satellite" />
            </div>
          </Col>
        </Row>
      </Container>
      <SatelliteInterference />
    </>
  );
};

export default LandingPage;
