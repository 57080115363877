import React, { useState } from "react";
import { FormControl, FormText } from "react-bootstrap";
import "../styles/InputField.css";

const InputField = ({ label, minValue, maxValue, unit, onChange, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Validate the input
    const numericValue = parseFloat(inputValue);
    let hasError = false;

    if (isNaN(numericValue)) {
      setError(`Invalid ${label.toLowerCase()}. Must be a number.`);
    } else if (
      (minValue !== undefined && numericValue < minValue) ||
      (maxValue !== undefined && numericValue > maxValue)
    ) {
      if (maxValue) {
        setError(
          `Invalid ${label.toLowerCase()}. Must be between ${minValue} and ${maxValue}.`
        );
      } else {
        setError(
          `Invalid ${label.toLowerCase()}. Must be greater than ${minValue}.`
        );
      }
      hasError = true;
    } else {
      setError("");
    }

    setValue(inputValue);

    // Notify parent component about the change
    onChange && onChange(inputValue, hasError);
  };

  const validateInput = () => {
    const numericValue = parseFloat(value);

    if (
      isNaN(numericValue) ||
      numericValue < minValue ||
      numericValue > maxValue
    ) {
      if (maxValue) {
        setError(
          `Invalid ${label.toLowerCase()}. Must be between ${minValue} and ${maxValue}.`
        );
        return false;
      } else {
        setError(
          `Invalid ${label.toLowerCase()}. Must be greater than ${minValue}.`
        );
        return false;
      }
    }

    setError("");
    return true;
  };

  return (
      <div className="input-container">
        <FormControl
          type="text"
          value={value}
          onChange={handleInputChange}
          onBlur={validateInput}
          className={error ? "input-field error" : "input-field"}
        />
        {error && <FormText className="text-danger">{error}</FormText>}
      </div>
  );
};

export default InputField;
