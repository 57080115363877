import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Alert } from "react-bootstrap";
import "../styles/SatelliteInterference.css";
import StarsBackground from "../components/starsBackground";
import InputTitle from "../components/inputTitle";
import InputField from "../components/inputField";
import Satellite from "../components/satellite";
import mockSatelliteData from "../data/satelliteData";

const SatelliteInterference = () => {
  const [counter, setCounter] = useState(1);
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [visibleSatellites, setVisibleSatellites] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [satelliteData, setSatelliteData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [longitude, setLongitude] = React.useState(-79);
  const [latitude, setLatitude] = React.useState(44);
  const [altitude, setAltitude] = React.useState(0);
  const [fovSize, setFovSize] = React.useState(7);
  const [exposureTime, setExposureTime] = React.useState(30);
  const [rightAscensionHours, setRightAscensionHours] = React.useState(2);
  const [rightAscensionMinutes, setRightAscensionMinutes] = React.useState(42);
  const [declinationDegrees, setDeclinationDegrees] = React.useState(89);
  const [declinationMinutes, setDeclinationMinutes] = React.useState(16);

  const [currentExposureTime, setCurrentExposureTime] = useState(exposureTime);

  const isSatelliteDataEmpty = Object.values(satelliteData).every(
    (arr) => arr.length === 0
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) =>
        prevCounter >= Object.keys(satelliteData).length ? 1 : prevCounter + 1
      );
    }, 250);

    return () => clearInterval(intervalId);
  }, [satelliteData]);

  useEffect(() => {
    setVisibleSatellites(satelliteData[counter] || []);
  }, [counter, satelliteData]);

  const handleSearch = async () => {
    // Reset error message
    setErrorMessage(null);
    setIsLoading(true);

    // Prepare input data
    const inputData = {
      observer_latitude: latitude,
      observer_longitude: longitude,
      observer_altitude: altitude,
      fov_size: fovSize,
      exposure_time: exposureTime,
      fov_center_ra_h: rightAscensionHours,
      fov_center_ra_m: rightAscensionMinutes,
      fov_center_dec_d: declinationDegrees,
      fov_center_dec_m: declinationMinutes,
    };

    try {
      // LOCAL API call
      // const response = await fetch("http://127.0.0.1:5000/satellite_alert", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(inputData),
      // });

      // PROD API call
      const response = await fetch(
        "https://satellitecatcher-v2.vercel.app/satellite_alert",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          accept: "application/json",
          body: JSON.stringify(inputData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("API call result:", result);
        setSatelliteData(result);
        setCurrentExposureTime(exposureTime);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.error);
        console.error("API call failed:", response.statusText);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error("API call error:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLatitudeChange = (latitude, hasError) => {
    latitude = parseInt(latitude);
    setLatitude(latitude);
    setIsSearchDisabled(hasError);
  };

  const handleLongitudeChange = (longitude, hasError) => {
    longitude = parseInt(longitude);
    setLongitude(longitude);
    setIsSearchDisabled(hasError);
  };

  const handleAltitudeChange = (altitude, hasError) => {
    altitude = parseInt(altitude);
    setAltitude(altitude);
    setIsSearchDisabled(hasError);
  };

  const handleFovSizeChange = (fovSize, hasError) => {
    fovSize = parseInt(fovSize);
    setFovSize(fovSize);
    setIsSearchDisabled(hasError);
  };

  const handleExposureTimeChange = (exposureTime, hasError) => {
    exposureTime = parseInt(exposureTime);
    setExposureTime(exposureTime);
    setIsSearchDisabled(hasError);
  };

  const handleRightAscensionHoursChange = (rightAscensionHours, hasError) => {
    rightAscensionHours = parseInt(rightAscensionHours);
    setRightAscensionHours(rightAscensionHours);
    setIsSearchDisabled(hasError);
  };

  const handleRightAscensionMinutesChange = (
    rightAscensionMinutes,
    hasError
  ) => {
    rightAscensionMinutes = parseInt(rightAscensionMinutes);
    setRightAscensionMinutes(rightAscensionMinutes);
    setIsSearchDisabled(hasError);
  };

  const handleDeclinationDegreesChange = (declinationDegrees, hasError) => {
    declinationDegrees = parseInt(declinationDegrees);
    setDeclinationDegrees(declinationDegrees);
    setIsSearchDisabled(hasError);
  };

  const handleDeclinationMinutesChange = (declinationMinutes, hasError) => {
    declinationMinutes = parseInt(declinationMinutes);
    setDeclinationMinutes(declinationMinutes);
    setIsSearchDisabled(hasError);
  };

  const remainingTimePercentage =
    ((exposureTime - counter) / exposureTime) * 100;

  return (
    <Container
      fluid
      className="satelliteInter-page"
      id="satellite-interference-page"
    >
      <StarsBackground numberOfStars={150} />
      <Row className="main-row-container">
        <Col className="mr-3 left-panel">
          <Row className="mt-3">
            <Col>
              <InputTitle title="Latitude:" />
            </Col>
            <Col>
              <InputField
                label="Latitude"
                minValue={-90}
                maxValue={90}
                defaultValue={44}
                onChange={handleLatitudeChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle title="Longitude:" />
            </Col>
            <Col>
              <InputField
                label="Longitude"
                minValue={-90}
                maxValue={90}
                defaultValue={-79}
                onChange={handleLongitudeChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle title="Altitude:" units="m above sea level" />
            </Col>
            <Col>
              <InputField
                label="Altitude"
                minValue={0}
                unit="meters"
                defaultValue={0}
                onChange={handleAltitudeChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle title="FOV Size:" units="degrees" />
            </Col>
            <Col>
              <InputField
                label="FOV size"
                minValue={0}
                maxValue={30}
                unit="degrees"
                defaultValue={7}
                onChange={handleFovSizeChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle title="Exposure Time:" units="seconds" />
            </Col>
            <Col>
              <InputField
                label="Exposure time"
                minValue={1}
                maxValue={500}
                unit="seconds"
                defaultValue={30}
                onChange={handleExposureTimeChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle
                context="Right Ascension"
                title="FOV Center:"
                units="hours"
              />
            </Col>
            <Col>
              <InputField
                label="Right Ascension of FOV center in hours"
                minValue={0}
                maxValue={23}
                unit="hours"
                defaultValue={2}
                onChange={handleRightAscensionHoursChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle
                context="Right Ascension"
                title="FOV Center:"
                units="minutes"
              />
            </Col>
            <Col>
              <InputField
                label="Right Ascension of FOV center in minutes"
                minValue={0}
                maxValue={59}
                unit="minutes"
                defaultValue={42}
                onChange={handleRightAscensionMinutesChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle
                context="Declination"
                title="FOV Center:"
                units="degrees"
              />
            </Col>
            <Col>
              <InputField
                label="Declination of FOV center in degrees"
                minValue={-90}
                maxValue={90}
                unit="degrees"
                defaultValue={89}
                onChange={handleDeclinationDegreesChange}
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <InputTitle
                context="Declination"
                title="FOV Center:"
                units="minutes"
              />
            </Col>
            <Col>
              <InputField
                label="Declination of FOV center in minutes"
                minValue={0}
                maxValue={59}
                unit="minutes"
                defaultValue={16}
                onChange={handleDeclinationMinutesChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 search">
            <Button
              className="search-button"
              disabled={isSearchDisabled || isLoading}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Row>
        </Col>

        <Col className="mx-auto text-center">
          <Row>
            <div className="fov-title">Your Field of View</div>
          </Row>
          <div className="fov-frame">
            {isLoading && (
              <Row className="message-box mt-3">
                <Alert variant="info" className="message">
                  Scanning the sky...
                </Alert>
              </Row>
            )}
            {/* Error message box */}
            {errorMessage && (
              <Row className="message-box mt-3">
                <Alert variant="danger" className="message">
                  {errorMessage}
                </Alert>
              </Row>
            )}
            {/* Render satellites and their future positions */}
            {!errorMessage &&
              !isLoading &&
              Object.values(satelliteData).map(
                (satelliteDataForSecond, index) => {
                  if (isSatelliteDataEmpty) {
                    return (
                      <Row className="message-box mt-3">
                        <Alert variant="info" className="message">
                          No Satellite Interference Found.
                        </Alert>
                      </Row>
                    );
                  }

                  return satelliteDataForSecond.map((satellite) => {
                    const { sid, delta_radius, delta_theta } = satellite;

                    var dotColor;
                    if (index === 0) {
                      dotColor = "green-dot";
                    } else if (index === satelliteDataForSecond.length - 2) {
                      dotColor = "red-dot";
                    } else {
                      dotColor = "blue-dot";
                    }

                    if (index >= counter) {
                      // Calculate future position
                      const x =
                        delta_radius * Math.sin(delta_theta * (Math.PI / 180));
                      const y =
                        delta_radius * Math.cos(delta_theta * (Math.PI / 180));
                      const right = 0.5 - x / 2 - 0.07;
                      const top = 0.5 - y / 2 - 0.07;

                      // Convert position to percentage
                      const rightPercentage = `${right * 100 + 7}%`;
                      const topPercentage = `${top * 100 + 7}%`;

                      // Render red dot for future position only if the satellite is visible
                      if (
                        visibleSatellites.find(
                          (visibleSatellite) =>
                            visibleSatellite.sid === satellite.sid
                        )
                      ) {
                        return (
                          <div
                            key={sid}
                            className={dotColor}
                            style={{
                              position: "absolute",
                              right: rightPercentage,
                              top: topPercentage,
                            }}
                          />
                        );
                      }
                    }

                    return null; // Return null if satellite is not visible
                  });
                }
              )}
            {/* Render satellites */}
            {!errorMessage &&
              !isLoading &&
              visibleSatellites.map((satellite, index) => (
                <Satellite key={index} satellite={satellite} />
              ))}
          </div>
          {/* Exposure time pill */}
          {!errorMessage && !isLoading && (
            <div>
              <Row>
                <div className="pill-title">Next {currentExposureTime} seconds</div>
              </Row>
              <div className="col exposure-pill-container">
                <div
                  className="exposure-pill"
                  style={{ width: `${remainingTimePercentage}%` }}
                ></div>
              </div> 
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SatelliteInterference;
