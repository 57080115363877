import React from "react";
import "../styles/InputTitle.css";

export const CONTEXT_RIGHT_ASCENSION = "Right Ascension";
export const CONTEXT_DECLINATION = "Declination";

const InputTitle = ({ context, title, units }) => {
  return (
    <div className="input-title-container">
      <div className="input-title">
        {context && (
          <div
            className={`${
              context === CONTEXT_RIGHT_ASCENSION
                ? "context-blue"
                : "context-purple"
            }`}
          >
            {context}
          </div>
        )}
        <div>{title}</div>
        {units && <div className="units">{units}</div>}
      </div>
    </div>
  );
};

export default InputTitle;
