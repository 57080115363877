import React, { useState } from "react";
import "../styles/Satellite.css";
import satelliteImage from "../images/satellite.png";
import TooltipCard from "./toolTipCard";

const Satellite = ({ satellite }) => {
  const { name, delta_radius, delta_theta, sid } = satellite;
  const [isHovered, setIsHovered] = useState(false);

  // Calculate satellite position within the FOV frame based on right ascension and declination
  const x = delta_radius * Math.sin(delta_theta * (Math.PI / 180));
  const y = delta_radius * Math.cos(delta_theta * (Math.PI / 180));

  const right = 0.5 - x / 2 - 0.056;
  const rightPercentage = `${right * 100}%`;

  const top = 0.5 - y / 2 - 0.056;
  const topPercentage = `${top * 100}%`;

  // Calculate rotation angle based on satellite's movement
  const rotationAngle = 90 + delta_theta;

  // console.log(right, top);
  // console.log(rightPercentage, topPercentage);
  // console.log(name, sid, right_ascension, declination, delta_radius, delta_theta);

  return (
    <div
      className="satellite"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        top: topPercentage,
        right: rightPercentage,
        // transform: `rotate(${rotationAngle}deg)`,
      }}
    >
      <img src={satelliteImage} alt={name} className="satellite-image" />
      {isHovered && <TooltipCard noradId={sid} name={name} />}
    </div>
  );
};

export default Satellite;
