import React from "react";
import PropTypes from "prop-types";
import "../styles/TooltipCard.css";

const TooltipCard = ({ noradId, name }) => {
  return (
    <div className="tooltip-card">
      <div className="tooltip-row">
        <span className="tooltip-title">NORAD ID</span>
        <span className="vertical-line"></span>
        <span className="tooltip-value">{noradId}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-title">NAME</span>
        <span className="tooltip-value">{name}</span>
      </div>
    </div>
  );
};

TooltipCard.propTypes = {
  noradId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default TooltipCard;